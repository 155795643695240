import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { keyBy } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Legend from '../legend';
import State from './State';
import NoData from './NoData';

import './map.css';

const Map = ({
  title,
  summary,
  values,
  legendValues,
  colors,
  scheme,
  narrow,
  probeValues,
  showBlank,
}) => (
  <StaticQuery
    query={graphql`
      {
        hope {
          allStates {
            abbreviation
            name
          }
        }
      }
    `}
    render={data => {
      const fullName = keyBy(data.hope.allStates, 'abbreviation');
      return (
        <Row className="map" noGutters>
          {!narrow && (
            <>
              <Col lg={3}>
                <h4 style={{ margin: 0 }}>{title}</h4>
                <Legend colors={scheme} values={legendValues} />
                <p className="summary">{summary}</p>
              </Col>
              <Col lg={1} />
            </>
          )}
          {Object.keys(values).length || showBlank ? (
            <Col className="choropleth" lg={narrow ? 12 : 8}>
              <Row>
                <State
                  name="AK"
                  fullName={fullName.AK.name}
                  value={values.AK}
                  color={colors.AK}
                  probeValues={probeValues.AK}
                />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <State
                  name="ME"
                  fullName={fullName.ME.name}
                  value={values.ME}
                  color={colors.ME}
                  probeValues={probeValues.ME}
                />
              </Row>
              <Row>
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <State
                  name="VT"
                  fullName={fullName.VT.name}
                  value={values.VT}
                  color={colors.VT}
                  probeValues={probeValues.VT}
                />
                <State
                  name="NH"
                  fullName={fullName.NH.name}
                  value={values.NH}
                  color={colors.NH}
                  probeValues={probeValues.NH}
                />
              </Row>
              <Row>
                <Col />
                <State
                  name="WA"
                  fullName={fullName.WA.name}
                  value={values.WA}
                  color={colors.WA}
                  probeValues={probeValues.WA}
                />
                <State
                  name="ID"
                  fullName={fullName.ID.name}
                  value={values.ID}
                  color={colors.ID}
                  probeValues={probeValues.ID}
                />
                <State
                  name="MT"
                  fullName={fullName.MT.name}
                  value={values.MT}
                  color={colors.MT}
                  probeValues={probeValues.MT}
                />
                <State
                  name="ND"
                  fullName={fullName.ND.name}
                  value={values.ND}
                  color={colors.ND}
                  probeValues={probeValues.ND}
                />
                <State
                  name="MN"
                  fullName={fullName.MN.name}
                  value={values.MN}
                  color={colors.MN}
                  probeValues={probeValues.MN}
                />
                <State
                  name="WI"
                  fullName={fullName.WI.name}
                  value={values.WI}
                  color={colors.WI}
                  probeValues={probeValues.WI}
                />
                <State
                  name="MI"
                  fullName={fullName.MI.name}
                  value={values.MI}
                  color={colors.MI}
                  probeValues={probeValues.MI}
                />
                <Col />
                <State
                  name="NY"
                  fullName={fullName.NY.name}
                  value={values.NY}
                  color={colors.NY}
                  probeValues={probeValues.NY}
                />
                <State
                  name="CT"
                  fullName={fullName.CT.name}
                  value={values.CT}
                  color={colors.CT}
                  probeValues={probeValues.CT}
                />
                <State
                  name="RI"
                  fullName={fullName.RI.name}
                  value={values.RI}
                  color={colors.RI}
                  probeValues={probeValues.RI}
                />
                <State
                  name="MA"
                  fullName={fullName.MA.name}
                  value={values.MA}
                  color={colors.MA}
                  probeValues={probeValues.MA}
                />
              </Row>
              <Row>
                <Col />
                <State
                  name="OR"
                  fullName={fullName.OR.name}
                  value={values.OR}
                  color={colors.OR}
                  probeValues={probeValues.OR}
                />
                <State
                  name="NV"
                  fullName={fullName.NV.name}
                  value={values.NV}
                  color={colors.NV}
                  probeValues={probeValues.NV}
                />
                <State
                  name="WY"
                  fullName={fullName.WY.name}
                  value={values.WY}
                  color={colors.WY}
                  probeValues={probeValues.WY}
                />
                <State
                  name="SD"
                  fullName={fullName.SD.name}
                  value={values.SD}
                  color={colors.SD}
                  probeValues={probeValues.SD}
                />
                <State
                  name="IA"
                  fullName={fullName.IA.name}
                  value={values.IA}
                  color={colors.IA}
                  probeValues={probeValues.IA}
                />
                <State
                  name="IL"
                  fullName={fullName.IL.name}
                  value={values.IL}
                  color={colors.IL}
                  probeValues={probeValues.IL}
                />
                <State
                  name="IN"
                  fullName={fullName.IN.name}
                  value={values.IN}
                  color={colors.IN}
                  probeValues={probeValues.IN}
                />
                <State
                  name="OH"
                  fullName={fullName.OH.name}
                  value={values.OH}
                  color={colors.OH}
                  probeValues={probeValues.OH}
                />
                <State
                  name="PA"
                  fullName={fullName.PA.name}
                  value={values.PA}
                  color={colors.PA}
                  probeValues={probeValues.PA}
                />
                <State
                  name="NJ"
                  fullName={fullName.NJ.name}
                  value={values.NJ}
                  color={colors.NJ}
                  probeValues={probeValues.NJ}
                />
                <State
                  name="DE"
                  fullName={fullName.DE.name}
                  value={values.DE}
                  color={colors.DE}
                  probeValues={probeValues.DE}
                />
                <Col />
              </Row>
              <Row>
                <Col />
                <State
                  name="CA"
                  fullName={fullName.CA.name}
                  value={values.CA}
                  color={colors.CA}
                  probeValues={probeValues.CA}
                />
                <State
                  name="UT"
                  fullName={fullName.UT.name}
                  value={values.UT}
                  color={colors.UT}
                  probeValues={probeValues.UT}
                />
                <State
                  name="CO"
                  fullName={fullName.CO.name}
                  value={values.CO}
                  color={colors.CO}
                  probeValues={probeValues.CO}
                />
                <State
                  name="NE"
                  fullName={fullName.NE.name}
                  value={values.NE}
                  color={colors.NE}
                  probeValues={probeValues.NE}
                />
                <State
                  name="MO"
                  fullName={fullName.MO.name}
                  value={values.MO}
                  color={colors.MO}
                  probeValues={probeValues.MO}
                />
                <State
                  name="KY"
                  fullName={fullName.KY.name}
                  value={values.KY}
                  color={colors.KY}
                  probeValues={probeValues.KY}
                />
                <State
                  name="WV"
                  fullName={fullName.WV.name}
                  value={values.WV}
                  color={colors.WV}
                  probeValues={probeValues.WV}
                />
                <State
                  name="VA"
                  fullName={fullName.VA.name}
                  value={values.VA}
                  color={colors.VA}
                  probeValues={probeValues.VA}
                />
                <State
                  name="DC"
                  fullName=""
                  value={values.DC}
                  color={colors.DC}
                  probeValues={probeValues.DC}
                />
                <State
                  name="MD"
                  fullName={fullName.MD.name}
                  value={values.MD}
                  color={colors.MD}
                  probeValues={probeValues.MD}
                />
                <Col />
                <Col />
              </Row>
              <Row>
                <Col />
                <Col />
                <State
                  name="AZ"
                  fullName={fullName.AZ.name}
                  value={values.AZ}
                  color={colors.AZ}
                  probeValues={probeValues.AZ}
                />
                <State
                  name="NM"
                  fullName={fullName.NM.name}
                  value={values.NM}
                  color={colors.NM}
                  probeValues={probeValues.NM}
                />
                <State
                  name="KS"
                  fullName={fullName.KS.name}
                  value={values.KS}
                  color={colors.KS}
                  probeValues={probeValues.KS}
                />
                <State
                  name="AR"
                  fullName={fullName.AR.name}
                  value={values.AR}
                  color={colors.AR}
                  probeValues={probeValues.AR}
                />
                <State
                  name="MS"
                  fullName={fullName.MS.name}
                  value={values.MS}
                  color={colors.MS}
                  probeValues={probeValues.MS}
                />
                <State
                  name="TN"
                  fullName={fullName.TN.name}
                  value={values.TN}
                  color={colors.TN}
                  probeValues={probeValues.TN}
                />
                <State
                  name="NC"
                  fullName={fullName.NC.name}
                  value={values.NC}
                  color={colors.NC}
                  probeValues={probeValues.NC}
                />
                <Col />
                <Col />
                <Col />
                <Col />
              </Row>
              <Row>
                <Col />
                <Col />
                <Col />
                <Col />
                <State
                  name="OK"
                  fullName={fullName.OK.name}
                  value={values.OK}
                  color={colors.OK}
                  probeValues={probeValues.OK}
                />
                <State
                  name="LA"
                  fullName={fullName.LA.name}
                  value={values.LA}
                  color={colors.LA}
                  probeValues={probeValues.LA}
                />
                <State
                  name="AL"
                  fullName={fullName.AL.name}
                  value={values.AL}
                  color={colors.AL}
                  probeValues={probeValues.AL}
                />
                <State
                  name="GA"
                  fullName={fullName.GA.name}
                  value={values.GA}
                  color={colors.GA}
                  probeValues={probeValues.GA}
                />
                <State
                  name="SC"
                  fullName={fullName.SC.name}
                  value={values.SC}
                  color={colors.SC}
                  probeValues={probeValues.SC}
                />
                <Col />
                <Col />
                <Col />
                <Col />
              </Row>
              <Row>
                <State
                  name="HI"
                  fullName={fullName.HI.name}
                  value={values.HI}
                  color={colors.HI}
                  probeValues={probeValues.HI}
                />
                <Col />
                <Col />
                <Col />
                <State
                  name="TX"
                  fullName={fullName.TX.name}
                  value={values.TX}
                  color={colors.TX}
                  probeValues={probeValues.TX}
                />
                <Col />
                <Col />
                <Col />
                <State
                  name="FL"
                  fullName={fullName.FL.name}
                  value={values.FL}
                  color={colors.FL}
                  probeValues={probeValues.FL}
                />
                <Col />
                <Col />
                <Col />
                <Col />
              </Row>
            </Col>
          ) : (
            <NoData />
          )}
        </Row>
      );
    }}
  />
);

Map.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
  values: PropTypes.shape(),
  colors: PropTypes.shape(),
  scheme: PropTypes.arrayOf(PropTypes.string).isRequired,
  narrow: PropTypes.bool,
  legendValues: PropTypes.arrayOf(PropTypes.number),
  probeValues: PropTypes.shape(),
  showBlank: PropTypes.bool,
};

Map.defaultProps = {
  title: null,
  summary: null,
  values: {},
  colors: {},
  narrow: false,
  legendValues: [],
  probeValues: {},
  showBlank: false,
};

export default Map;
