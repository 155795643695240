import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { concat, omit } from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';

import SectionButtons from '../components/page/sectionButtons';
import HeatMap from '../components/graphics/heatmap';
import Compare from '../components/graphics/compare';

import Layout from '../components/layout';

import '../styles/demographic.css';

export const query = graphql`
  {
    hope {
      allDomains(orderBy: "order") {
        key
        name
        color
        demographic
        indicator(orderBy: "name") {
          key
          name
          format
          disclaimer: raceDisclaimer
          national {
            aian: aian_dtgcat
            aspi: aspi_dtgcat
            black: black_dtgcat
            hisp: hisp_dtgcat
            multi: multi_dtgcat
            white: white_dtgcat
          }
          national_labels: national {
            aian: aian_rate
            aspi: aspi_rate
            black: black_rate
            hisp: hisp_rate
            multi: multi_rate
            white: white_rate
          }
          values {
            aian: aian_dtgcat
            aian_rate
            aspi: aspi_dtgcat
            aspi_rate
            black: black_dtgcat
            black_rate
            hisp: hisp_dtgcat
            hisp_rate
            multi: multi_dtgcat
            multi_rate
            white: white_dtgcat
            white_rate
            state_rate
            state {
              abbreviation
            }
          }
          domain {
            color
          }
        }
      }
    }
  }
`;

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionToggle(eventKey);
  const currentEventKey = useContext(AccordionContext);

  return (
    <Button
      className={currentEventKey === eventKey ? 'active' : ''}
      type="button"
      variant="link"
      eventKey={eventKey}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
};

const DemographicPage = ({ data }) => {
  const indicators = data.hope.allDomains.reduce(
    (memo, d) =>
      concat(
        memo,
        d.indicator.map(i => omit(i, 'national'))
      ),
    []
  );

  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <h1>HOPE Data by Race &amp; Ethnicity</h1>
            <SectionButtons />
            <h3 style={{ marginTop: 60, marginBottom: 20 }}>
              National Rates by Race &amp; Ethnicity
            </h3>
            <Accordion>
              {data.hope.allDomains.map(domain => (
                <Card key={domain.key}>
                  <Card.Header style={{ backgroundColor: domain.color }}>
                    <CustomToggle eventKey={domain.key}>{domain.name}</CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={domain.key}>
                    <Card.Body>
                      <HeatMap
                        summary={domain.demographic}
                        color={domain.color}
                        indicators={domain.indicator}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3> Indicator Maps by Race &amp; Ethnicity</h3>
          </Col>
        </Row>
        <Compare indicators={indicators} />
      </Container>
    </Layout>
  );
};

DemographicPage.propTypes = {
  data: PropTypes.shape({
    hope: PropTypes.shape(),
  }).isRequired,
};

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.string.isRequired,
};

export default DemographicPage;
