import { last } from 'lodash';
import { interpolateRgb } from 'd3-interpolate';

const colorSchemes = {
  '#303f9f': ['#c5cae9', '#9fa8da', '#5c6bc0', '#3949ab', '#1a237e'],
  '#0277bd': ['#badefa', '#8fc9fa', '#42a6f5', '#1a75d1', '#0d47a1'],
  '#009688': ['#b2dedb', '#80ccc4', '#26a699', '#008a7a', '#00695c'],
  '#4caf50': ['#c7e5c9', '#a6d6a6', '#66ba6b', '#42a147', '#2e7d33'],
  '#c0ca33': ['#f0f5c2', '#dbe875', '#bfc933', '#9e9e24', '#827817'],
};

const classedChoropleth = (values, color, factor = 1, reverse) =>
  values.map(v => {
    let colorScheme = [...colorSchemes[color.toLowerCase()]];
    if (reverse) colorScheme = colorScheme.reverse();
    return {
      ...v,
      state: v.state.abbreviation,
      color:
        v.score !== null
          ? colorScheme[Math.max(0, Math.floor(v.score / factor))]
          : 'rgb(204, 204, 204)',
    };
  });

const unclassedChoropleth = (values, color, factor, range) => {
  const colorScale = interpolateRgb(colorSchemes[color][0], last(colorSchemes[color]));
  return values
    .filter(v => v.score !== null)
    .map(v => ({
      ...v,
      state: v.state.abbreviation,
      color: colorScale(Math.ceil(v.score / factor) / range),
    }));
};

const extractObject = (values, prop) => {
  const obj = {};
  values.forEach(v => {
    obj[v.state] = v[prop];
  });
  return obj;
};

export { colorSchemes, classedChoropleth, unclassedChoropleth, extractObject };
