import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import { format } from 'd3-format';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import IndicatorMenu from '../../page/indicatorMenu';
import Map from '../map';
import Legend from '../legend';
import RaceMenu from '../../page/raceMenu';
import { colorSchemes, unclassedChoropleth, extractObject } from '../../util/choropleth';
import { races } from '../../util/race';

const Compare = ({ indicators }) => {
  const [indicator, setIndicator] = useState(indicators[0]);
  const [race1, setRace1] = useState('black');
  const [race2, setRace2] = useState(null);
  const [data1, setData1] = useState({ colors: {}, values: {}, scheme: [], probeValues: {} });
  const [data2, setData2] = useState({ colors: {}, values: {}, scheme: [], probeValues: {} });

  useEffect(() => {
    const updater = (race, setter) => {
      const values = indicator.values.map(i => ({
        score: i[race] !== null ? Math.abs(i[race] - 9) : null,
        label: i[`${race}_rate`],
        state_rate: i.state_rate,
        state: i.state,
      }));

      const classification = unclassedChoropleth(values, indicator.domain.color, 1, 9);
      const scheme = colorSchemes[indicator.domain.color.toLowerCase()];

      const probeValues = {};
      values.forEach(v => {
        const raceName = races.find(r => r.key === race).name;
        probeValues[v.state.abbreviation] = [
          { label: `${raceName} Rate`, value: format(indicator.format)(v.label) },
          { label: 'State Rate', value: format(indicator.format)(v.state_rate) },
        ];
      });

      setter({
        colors: extractObject(classification, 'color'),
        scheme: [last(scheme), scheme[0]],
        probeValues,
      });
    };

    if (race1) {
      updater(race1, setData1);
    } else {
      setData1({ colors: {}, values: {}, scheme: [] });
    }
    if (race2) {
      updater(race2, setData2);
    } else {
      setData2({ colors: {}, values: {}, scheme: [] });
    }
  }, [indicator, race1, race2]);

  return (
    <>
      <Row>
        <Col lg={4}>
          <h3 style={{ color: indicator.domain.color }}>{indicator.name}</h3>
        </Col>
        <Col style={{ marginTop: 12 }}>
          <IndicatorMenu handler={ind => setIndicator(indicators.find(i => i.key === ind.key))} />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{indicator.summary}</p>
          {data1.scheme.length > 0 && (
            <Row noGutters>
              <Col md={5} xs={12}>
                <Legend colors={data1.scheme} width={3} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <RaceMenu selected={race1} handler={setRace1} />
          <Map narrow showBlank {...data1} />
        </Col>
        <Col lg={6}>
          <RaceMenu selected={race2} handler={setRace2} />
          <Map narrow showBlank {...data2} />
        </Col>
      </Row>
      {indicator.disclaimer && (
        <Row>
          <Col>
            <p style={{ fontSize: '0.75em', lineHeight: 1.25, textIndent: -8, marginLeft: 8 }}>
              {`* ${indicator.disclaimer}`}
            </p>
          </Col>
        </Row>
      )}
    </>
  );
};

Compare.propTypes = {
  indicators: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Compare;
