import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { races } from '../../util/race';

const RaceMenu = ({ selected, handler }) => {
  return (
    <DropdownButton
      className="domainMenu"
      title={selected ? races.find(r => r.key === selected).name : 'Select Race'}
    >
      {races.map(race => (
        <Dropdown.Item
          key={race.key}
          as={Button}
          onClick={() => handler(race.key)}
          active={selected === race.key}
        >
          {race.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

RaceMenu.propTypes = {
  selected: PropTypes.string,
  handler: PropTypes.func.isRequired,
};

RaceMenu.defaultProps = {
  selected: null,
};

export default RaceMenu;
