const races = [
  { key: 'aian', name: 'AI/AN' },
  { key: 'aspi', name: 'Asian/PI' },
  { key: 'black', name: 'Black' },
  { key: 'hisp', name: 'Hispanic' },
  { key: 'multi', name: 'Multiracial' },
  { key: 'white', name: 'White' },
];

const raceScheme = {
  aian: '#fdd838',
  aspi: '#c0ca33',
  black: '#0277bd',
  hisp: '#009688',
  multi: '#f9a01b',
  white: '#303f9f',
};

export { races, raceScheme };
