import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Col from 'react-bootstrap/Col';

import Probe from './Probe';

const State = ({ name, fullName, value, color, probeValues }) => {
  const [showProbe, setShowProbe] = useState(false);
  const [probePosition, setProbePosition] = useState({ left: null, top: null });

  return (
    <>
      {showProbe && probeValues && (
        <Probe {...probePosition} name={fullName} values={probeValues} />
      )}
      <Col
        style={{ backgroundColor: color }}
        onClick={() => {
          if (value) navigate(`/state/${fullName.replace(/(\W+)/gm, '-').toLowerCase()}`);
        }}
        onMouseOver={e => {
          setProbePosition({ left: e.clientX - 125, top: e.clientY - 100 });
          setShowProbe(true);
        }}
        onMouseOut={() => setShowProbe(false)}
        className={value === null ? 'no-data' : ''}
      >
        {value !== null && <div className="value">{value}</div>}
        <div className="name">{name}</div>
      </Col>
    </>
  );
};

State.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  probeValues: PropTypes.arrayOf(PropTypes.shape()),
};

State.defaultProps = {
  name: null,
  value: null,
  color: '#cfd8dc',
  probeValues: null,
};

export default State;
