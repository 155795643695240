import React from 'react';
import PropTypes from 'prop-types';

const Probe = ({ left, top, name, values }) => (
  <div className="probe" style={{ left, top }}>
    <div className="stateName">{name}</div>
    <div>
      {values.map(val => (
        <div key={val.label} className="probeValue">
          <b>{val.label}</b>
          <br />
          {val.value}
        </div>
      ))}
    </div>
  </div>
);

Probe.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  name: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape()),
};

Probe.defaultProps = {
  left: 0,
  top: 0,
  name: '',
  values: [],
};

export default Probe;
