import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './legend.css';

const Legend = ({ colors, values }) => {
  const unclassed = () => (
    <>
      <Col
        xs={10}
        sm={8}
        className="ramp"
        style={{
          background: `linear-gradient(to right, ${colors[1]} 0%,${colors[0]} 100%)`,
        }}
      >
        <div className="value words">
          <br />
          <br />
          {values && values.length === 2 ? (
            <>{values[0]}</>
          ) : (
            <>
              FURTHER
              <br />
              FROM GOAL
            </>
          )}
        </div>
        &nbsp;
        <div className="value words max">
          {values && values.length === 2 ? (
            <>{values[1]}</>
          ) : (
            <>
              CLOSER
              <br />
              TO GOAL
            </>
          )}
        </div>
      </Col>
    </>
  );
  const classed = () => (
    <>
      {colors.map((color, i) => (
        <Col key={color} style={{ backgroundColor: color }} className="ramp">
          &nbsp;
          {values.length > 0 && <div className="value">{values[i]}</div>}
          {i + 2 === values.length && (
            <div className="value max">
              <br />
              {values[values.length - 1]}
            </div>
          )}
          {values.length === 0 && i === 0 && (
            <div className="value words">
              FURTHER
              <br />
              FROM GOAL
            </div>
          )}
          {values.length === 0 && i + 1 === colors.length && (
            <div className="value words max">
              CLOSER
              <br />
              TO GOAL
            </div>
          )}
        </Col>
      ))}
    </>
  );
  return (
    <Row className="legend">
      {colors.length === 2 ? unclassed() : classed()}
      <Col />
      <Col style={{ backgroundColor: '#cfd8dc' }} className="ramp">
        <div className="value na">Not available</div>
      </Col>
    </Row>
  );
};

Legend.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.number),
};

Legend.defaultProps = {
  values: [],
};

export default Legend;
