import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { interpolateRgb } from 'd3-interpolate';
import { format } from 'd3-format';
import { mapValues, last } from 'lodash';

import { colorSchemes } from '../../util/choropleth';
import Legend from '../legend';

import './heatmap.css';

const HeatMap = ({ summary, color, indicators }) => {
  const scheme = colorSchemes[color.toLowerCase()];
  const colorScale = interpolateRgb(last(scheme), scheme[0]);
  const data = indicators
    .filter(i => i.national && Object.values(i.national).find(v => v))
    .map(d => ({
      ...d,
      national: mapValues(d.national, v => (v !== null ? colorScale(v / 9) : 'rgb(207, 216, 220)')),
    }));

  return (
    <div style={{ marginBottom: 30 }}>
      <Row noGutters style={{ marginBottom: 20 }}>
        <Col lg={6} style={{ paddingRight: 40, fontSize: 14 }}>
          <p>
            National rates are displayed by race and ethnicity below and each group is shaded for
            performance on reaching the HOPE Goal (Distance to Goal).
          </p>
          <p>{summary}</p>
        </Col>
        <Col lg={6} md={8} style={{ marginTop: -20 }}>
          {scheme[0] && <Legend colors={[colorScale(0), colorScale(1)]} />}
        </Col>
      </Row>
      <div className="heatmap">
        <Row className="headers" noGutters>
          <Col lg={3} md={4} xs={4} />
          <Col>
            <span>White</span>
          </Col>
          <Col>
            <span>Black</span>
          </Col>
          <Col>
            <span>Hispanic</span>
          </Col>
          <Col>
            <span>Asian/PI</span>
          </Col>
          <Col>
            <span>AI/AN</span>
          </Col>
          <Col>
            <span>Multiracial</span>
          </Col>
        </Row>
        {data.map(indicator => {
          let { name } = indicator;
          if (name === 'Infant Mortality') name += ' (# per 1,000)';
          else if (name === 'Premature Mortality') name += ' (# per 100,000)';
          return (
            <Row key={indicator.name} noGutters>
              <Col className="indicatorLabel" lg={3} md={4} xs={4}>
                {name}
              </Col>
              <Col className="value" style={{ backgroundColor: indicator.national.white }}>
                {indicator.national_labels.white !== null &&
                  format(indicator.format)(indicator.national_labels.white)}
              </Col>
              <Col className="value" style={{ backgroundColor: indicator.national.black }}>
                {indicator.national_labels.black !== null &&
                  format(indicator.format)(indicator.national_labels.black)}
              </Col>
              <Col className="value" style={{ backgroundColor: indicator.national.hisp }}>
                {indicator.national_labels.hisp !== null &&
                  format(indicator.format)(indicator.national_labels.hisp)}
              </Col>
              <Col className="value" style={{ backgroundColor: indicator.national.aspi }}>
                {indicator.national_labels.aspi !== null &&
                  format(indicator.format)(indicator.national_labels.aspi)}
              </Col>
              <Col className="value" style={{ backgroundColor: indicator.national.aian }}>
                {indicator.national_labels.aian !== null &&
                  format(indicator.format)(indicator.national_labels.aian)}
              </Col>
              <Col className="value" style={{ backgroundColor: indicator.national.multi }}>
                {indicator.national_labels.multi !== null &&
                  format(indicator.format)(indicator.national_labels.multi)}
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

HeatMap.propTypes = {
  summary: PropTypes.string,
  color: PropTypes.string.isRequired,
  indicators: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

HeatMap.defaultProps = {
  summary: null,
};

export default HeatMap;
